import Link from "next/link"
import { Spinner } from "@/common/loading/spinner"
import Arrow from "@/common/icons/arrow"
import styles from "./button.module.css"
import classNames from "classnames"

export default function Button({
  theme = "summer-green",
  size = "normal",
  font = "serif",
  tagName = "button",
  children,
  onClick,
  className = "",
  disabled = false,
  loading = false,
  href = "",
  title = null,
  ariaLabel = null,
  target = null,
  type = "button"
}) {
  const Loading = () => {
    if (!loading) {
      return null
    }

    let borderClass, spinColor

    switch (theme) {
      case "black":
      case "campaign-black":
        borderClass = "border-black-75"
        spinColor = "rgba(255, 255, 255, 0.4)"
        break

      default:
        borderClass = "border-black-100/10"
        spinColor = "rgba(0, 0, 0, 0.4)"
        break
    }

    return (
      <Spinner
        size="small"
        borderClass={borderClass}
        spinColor={spinColor}
        className="inline-block ml-3 align-middle"></Spinner>
    )
  }

  const classes = [
    styles.button,
    styles["size-" + size],
    styles["text-" + font],
    styles["theme-" + theme],
    className,
    "btn"
  ]

  ariaLabel = ariaLabel ?? title
  ariaLabel = ariaLabel ? ariaLabel : extractTextFromChildren(children)

  const attrs = Object.fromEntries(
    Object.entries({ title: title, target: target }).filter(
      ([, value]) => value !== null && value !== ""
    )
  )

  if (tagName === "a") {
    return (
      <Link
        href={href}
        prefetch={false}
        aria-label={ariaLabel}
        className={classNames(classes, {
          disabled: disabled
        })}
        {...attrs}
        onClick={onClick}>
        {theme === "text" && <Arrow className={styles.arrow} />}
        {children}
        <Loading />
      </Link>
    )
  }

  return (
    <button
      data-theme={theme}
      aria-label={ariaLabel}
      className={classNames(classes)}
      onClick={onClick}
      type={type}
      disabled={disabled}>
      {theme === "text" && <Arrow className={styles.arrow} />}
      {children}
      <Loading />
    </button>
  )
}

const extractTextFromChildren = (children) => {
  if (typeof children === "string") {
    return children
  }

  if (Array.isArray(children)) {
    return children.map(extractTextFromChildren).join("")
  }

  if (typeof children === "object" && children?.props?.children) {
    return extractTextFromChildren(children.props.children)
  }

  return ""
}
