import { useEffect, useRef, useState } from "react"
import {
  getMemberToken,
  decodeToken,
  deleteMemberCookie,
  setMemberCookie
} from "@/lib/helpers/member"
import Modal from "react-modal"
import { usePathData } from "@/lib/contexts/appContext"
import { renewMemberToken } from "@/lib/api/member"
import { useRouter } from "next/router"
import Button from "@/components/common/button/button"
import styles from "@/components/base/member/statusCheck.module.css"

export default function MemberStatusCheck() {
  const router = useRouter()
  const pathData = usePathData()
  const [timeLeft, setTimeLeft] = useState(0)
  const [timeLeftModalShow, setTimeLeftModalShow] = useState(false)
  const [isFetchingToken, setIsFetchingToken] = useState(false)
  const statusTimer = useRef(null)
  const logoutTimer = useRef(null)

  useEffect(() => {
    const token = getMemberToken()
    if (!token) {
      clearInterval(statusTimer.current)
      clearInterval(logoutTimer.current)
      return
    }

    if (statusTimer.current || logoutTimer.current) {
      return
    }

    startTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData, statusTimer])

  function startTimer() {
    if (statusTimer.current || logoutTimer.current) {
      return
    }

    statusTimer.current = setInterval(function () {
      const token = getMemberToken()
      if (!token) {
        clearInterval(statusTimer.current)
        return
      }

      let data = decodeToken(token)

      setTimeLeft(Math.round(data.exp - Date.now() / 1000))

      if (data.exp - Date.now() / 1000 < 300) {
        clearInterval(statusTimer.current)

        setTimeLeftModalShow(true)

        logoutTimer.current = setInterval(function () {
          const token = getMemberToken()
          if (!token) {
            clearInterval(logoutTimer.current)
            return
          }
          let data = decodeToken(token)
          setTimeLeft(Math.round(data.exp - Date.now() / 1000))

          if (Math.round(data.exp - Date.now() / 1000) <= 0) {
            // Just log out the user and redirect to current site
            setTimeLeftModalShow(false)
            deleteMemberCookie()
            clearInterval(logoutTimer.current)
            router.replace(router.asPath)
          }
        }, 1000)
      }
    }, 20000)
  }

  const customStyles = {
    overlay: {
      display: "flex",
      backgroundColor: "rgba(0, 0, 0, .5)",
      zIndex: 1000,
      alignItems: "center",
      justifyContent: "center"
    },
    maxWidth: "200px"
  }

  return (
    <Modal
      isOpen={timeLeftModalShow}
      style={customStyles}
      shouldReturnFocusAfterClose={false}
      shouldCloseOnOverlayClick={false}
      className={styles.content}>
      <div className="text-center content-wrapper">
        <h2>Du håller på att bli utloggad!</h2>
        <p>Utloggning sker om {timeLeft} sekunder</p>
        <div>
          <Button
            theme="lime"
            ariaLabel="Fortsätt vara inloggad"
            className="mx-4 button"
            loading={isFetchingToken}
            onClick={() => fetchMemberToken(true)}>
            Fortsätt vara inloggad
          </Button>
          <Button
            theme="secondary"
            className="mx-4 button"
            ariaLabel="Logga ut mig"
            onClick={() => {
              setTimeLeftModalShow(false)
              deleteMemberCookie()
              clearInterval(logoutTimer.current)
              router.replace(router.asPath)
            }}>
            Logga ut mig
          </Button>
        </div>
      </div>
    </Modal>
  )

  async function fetchMemberToken() {
    setIsFetchingToken(true)
    const token = getMemberToken()
    const response = await renewMemberToken(token)

    if (response.status !== "complete") {
      // Logout the member
      setTimeLeftModalShow(false)
      deleteMemberCookie()
      clearInterval(logoutTimer.current)
      router.replace(router.asPath)
    }

    if (response.token !== "") {
      setMemberCookie(response.token)
      setTimeLeftModalShow(false)
      setIsFetchingToken(false)
      clearInterval(statusTimer.current)
      clearInterval(logoutTimer.current)
      statusTimer.current = null
      logoutTimer.current = null
      startTimer()
    }
  }
}
