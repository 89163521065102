import { useCopy, usePropsObject } from "@/lib/contexts/appContext"
import { useTemplate } from "@/lib/helpers/template"
import Social from "@/common/social/social"
import Logo from "@/common/logos/logo"
import { NinetyAccount } from "@/components/common/logos/NinetyAccount"

import Primary from "./menus/primary"
import Secondary from "./menus/secondary"
import Tertiary from "./menus/tertiary"
import Template from "./template"
import Content from "./content"
import Partners from "./partners"
import Newsletter from "./newsletter"
import styles from "./footer.module.css"
import classNames from "classnames"

export default function Footer({ hasSidebar }) {
  const copy = useCopy()
  const object = usePropsObject()
  const blockTemplate = useTemplate()
  const { template, hideForm } = copy.footer
  const { year, publisher } = copy.general

  const pageColor = object?.pageColor ?? ""
  const hasCustomColor = pageColor.startsWith("#")

  const classes = [
    styles.footer,
    ["article", "event"].includes(blockTemplate) ? "white" : null,
    pageColor && !hasCustomColor ? object.pageColor : null,
    blockTemplate === "campaign" ? "campaign-" + object?.theme?.bgColor : "",
    { "has-sidebar": hasSidebar }
  ]

  const footerStyles = hasCustomColor ? { backgroundColor: pageColor } : {}

  return (
    <footer className={classNames(classes)} style={footerStyles}>
      {<Template content={template} />}
      {!hideForm && <Newsletter />}
      <div className="inner-wrapper">
        <div className="logos">
          <Logo className="logo" />
          <div>
            <NinetyAccount className="ninety" />
            {copy.general.pg && (
              <span className="inline-block mt-1 font-bold">
                PG: {copy.general.pg}
              </span>
            )}
          </div>
        </div>
        <Primary />
        <Tertiary />
        <Content />
        <div className={styles.section}>
          <Partners />
          <Social styles={styles} location="primary" />
        </div>
        <div className="bottom-row">
          <span className="publisher">
            &copy; {year} {publisher}
          </span>
          <Secondary />
        </div>
      </div>
    </footer>
  )
}
