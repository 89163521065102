import useMenuItems from "@/lib/helpers/menu"
import Link from "@/components/common/menu/link"
import MemberMenuItem from "@/components/base/member/menuItem"
import styles from "./top.module.css"

export default function MenuTop({
  hasMounted,
  isNarrow,
  isMobile,
  handleOverlay
}) {
  const location = "top"
  const items = useMenuItems(location)
  if (!items) {
    return null
  }

  if (hasMounted && isNarrow) {
    return null
  }

  return (
    <>
      <ul
        className={styles.menu}
        role="menu"
        data-location={location + "-menu"}>
        {items.map((item) => (
          <li key={item.url}>
            <Link item={item} location={location} />
          </li>
        ))}
        <MemberMenuItem isMobile={isMobile} handleOverlay={handleOverlay} />
      </ul>
    </>
  )
}
