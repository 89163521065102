import { useState, useEffect } from "react"
import Blocks from "@/components/Blocks"
import Button from "@/common/button/button"
import styles from "./sidebar.module.css"
import { useAppContext } from "@/lib/contexts/appContext"
import Close from "@/common/icons/close"
import NameCollection from "@/components/blocks/theme/name-collection/name-collection"
import classNames from "classnames"

export default function Sidebar({ block }) {
  const { mainElem, hasSentGravityForm } = useAppContext()
  const [activeSidebar, setActiveSidebar] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [scrollY, setScrollY] = useState(0)

  const handleScroll = () => {
    setScrollY(window.scrollY)
    setShowButton(
      mainElem.current.getBoundingClientRect().bottom > window.innerHeight
    )
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true
    })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!block.innerBlocks) {
    return null
  }

  const handleClick = () => {
    setActiveSidebar((prevStatus) => !prevStatus)
  }

  let collectionBlock = null
  if (block?.attrs?.showProgress) {
    collectionBlock = block.innerBlocks.filter(
      (block) => block.name === "theme/name-collection"
    )

    collectionBlock =
      collectionBlock.length === 0 ? null : collectionBlock.shift()
  }

  const settings = {
    "core/heading": { width: "bleed" },
    "core/paragraph": { width: "bleed" },
    "theme/gravity-form": { width: "bleed" }
  }

  return (
    <aside className={classNames(styles.aside)}>
      <div className={classNames(styles.sidebar, { active: activeSidebar })}>
        <div className="container">
          <button aria-label="Stäng" className="close" onClick={handleClick}>
            <Close />
          </button>
          <Blocks blocks={block.innerBlocks} settings={settings} />
        </div>
      </div>
      {!activeSidebar && scrollY > 100 && showButton && !hasSentGravityForm && (
        <div className="floating-wrapper">
          {collectionBlock && (
            <NameCollection
              block={collectionBlock}
              settings={{ onlyProgress: true }}
            />
          )}
          <Button
            className="toggle-button"
            ariaLabel={block.attrs?.button}
            theme={block.attrs?.theme ?? "lime"}
            onClick={handleClick}>
            {block.attrs?.button}
          </Button>
        </div>
      )}
    </aside>
  )
}
