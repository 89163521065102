import cx from "classnames"
import { useToaster } from "react-hot-toast"

const Notifications = () => {
  const { toasts, handlers } = useToaster()
  const { startPause, endPause, calculateOffset, updateHeight } = handlers

  if (!toasts.length) {
    return null
  }

  return (
    <div
      className="fixed right-0 z-50 w-full top-20 xl:top-30 no-print"
      onMouseEnter={startPause}
      onMouseLeave={endPause}>
      {toasts.map((toast) => {
        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 8
        })

        const ref = (el) => {
          if (el && !toast.height) {
            const height = el.getBoundingClientRect().height
            updateHeight(toast.id, height)
          }
        }

        const toastClassNames = cx(
          "absolute right-0 inline-block w-auto max-w-full p-4 text-black font-thin rounded",
          {
            "bg-summer-green-100": toast.type === "success",
            "bg-red-100": toast.type !== "success"
          }
        )

        return (
          <div
            className={toastClassNames}
            key={toast.id}
            ref={ref}
            style={{
              transition: "all 0.5s ease-out",
              opacity: toast.visible ? 1 : 0,
              transform: `translateY(${offset}px)`
            }}
            {...toast.ariaProps}>
            {toast.message instanceof Function
              ? toast.message()
              : toast.message}
          </div>
        )
      })}
    </div>
  )
}

export default Notifications
