import { useRef, useEffect } from "react"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import isEmail from "validator/lib/isEmail"
import { newsletterSignup } from "@/lib/api/newsletter"
import { cleanEmail } from "@/lib/helpers/email"
import { useCopy } from "@/lib/contexts/appContext"
import { Spinner } from "@/components/common/loading/spinner"
import styles from "./newsletter.module.css"
import classNames from "classnames"

export default function Newsletter() {
  const copy = useCopy()
  const { template } = copy.footer
  const { heading, text, placeholder, formText, buttonText, successText } =
    copy.footer.newsletter

  const lastBlock = () => {
    return template.length > 0 && template[template.length - 1]
  }

  const theme =
    lastBlock()?.name === "core/group" ? lastBlock().attrs.theme : ""

  return (
    <div className={classNames(styles.newsletter, theme)}>
      <div className="inner-wrapper">
        <div className="info">
          <h2>{heading}</h2>
          <p dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
        <SignupForm
          placeholder={placeholder}
          formText={formText}
          buttonText={buttonText}
          successText={successText}
        />
      </div>
    </div>
  )
}

function SignupForm({
  placeholder = "",
  formText = "",
  buttonText = "",
  successText = ""
}) {
  const recaptchaRef = useRef()
  const siteKey = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY
  const methods = useForm({
    mode: "all"
  })

  const onSubmit = async ({ email }) => {
    const captcha = await recaptchaRef.current.executeAsync()
    await newsletterSignup(email, captcha)
  }

  const { formState, register, handleSubmit, setValue, watch } = methods
  const email = watch("email")

  useEffect(() => {
    if (email) {
      setValue("email", cleanEmail(email))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <ReCAPTCHA
        className="hidden"
        ref={recaptchaRef}
        size="invisible"
        sitekey={siteKey}
      />
      <input
        className={styles.input}
        type="email"
        {...register("email", {
          required: true,
          validate: (value) => isEmail(value) || "Ange en giltig e-postadress"
        })}
        required
        placeholder={placeholder}
      />
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: formText }}
      />
      <button
        className={styles.button}
        aria-label={buttonText}
        disabled={!formState.isValid ? "disabled" : ""}
        type="submit">
        {formState.isSubmitSuccessful ? successText : buttonText}
      </button>
      {formState.isSubmitting && (
        <Spinner
          className={styles.spinner}
          sizeClasses="w-8 h-8 border-4"
          borderClass="border-black-50"
          spinColor="#FFFFFF"
        />
      )}
    </form>
  )
}
