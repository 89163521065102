import { NinetyAccount } from "@/components/common/logos/NinetyAccount"
import Social from "@/common/social/social"
import styles from "./footer.module.css"
import classNames from "classnames"

export default function Footer({ location }) {
  return (
    <>
      <Social styles={styles} location={location} />
      <NinetyAccount
        className={classNames("max-w-[140px] mx-auto", {
          "text-white": location === "primary"
        })}
      />
    </>
  )
}
