import { setCookie, hasCookie, deleteCookie } from "cookies-next"
import { useEffect, useState, useRef } from "react"
import { useMember } from "@/lib/hooks/useMember"
import { usePropsObject } from "@/lib/contexts/appContext"
import { fetchSSOToken } from "@/lib/api/sso"
import useBrowser from "@/lib/hooks/useBrowser"
import Modal from "react-modal"
import LogoSpinner from "@/components/common/loading/logo-spinner"
import Button from "@/components/common/button/button"
import ReCAPTCHA from "react-google-recaptcha"
import styles from "./sso-modal.module.css"
import classNames from "classnames"

export default function SSOModal() {
  const defaultData = {
    message: "Loggar in...",
    error: "Något gick snett.",
    button: "Prova igen"
  }

  const object = usePropsObject()
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState(defaultData.message)
  const [error, setError] = useState(defaultData.error)
  const [button, setButton] = useState(defaultData.button)
  const [externalUrl, setExternalUrl] = useState(null)
  const [showError, setShowError] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const recaptchaRef = useRef()
  const siteKey = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY
  const { token } = useMember()
  const { isMobile } = useBrowser()

  Modal.setAppElement("html")

  const reset = () => {
    setLoading(true)
    setShowError(false)
    openModal()
  }

  const openModal = async (event) => {
    event?.preventDefault()

    const elem = !event.target.classList.contains("toggle-sso-modal")
      ? event?.target?.closest(".toggle-sso-modal")
      : event.target

    if (object.ssoModals[elem.dataset.service]) {
      setMessage(object.ssoModals[elem.dataset.service].inProgress ?? null)
      setButton(object.ssoModals[elem.dataset.service].button ?? "Prova igen")
      setError(object.ssoModals[elem.dataset.service].hasError ?? false)
    } else {
      setMessage(error)
      setShowError(true)
    }

    setModalIsOpen(true)
    const captcha = await recaptchaRef.current.executeAsync()
    const response = await fetchSSOToken(token, captcha, elem.dataset.service)

    setLoading(false)

    if (!response || !response?.status || !response.token) {
      setMessage(error)
      setShowError(true)
      return
    }

    setCookie(response.cookie, response.token, {
      path: "/",
      domain: response.domain,
      sameSite: "none",
      maxAge: 120,
      secure: true
    })

    if (response.url && !isMobile) {
      window.open(response.url, "_blank")
      setModalIsOpen(false)
      setLoading(true)
    }

    if (response.url && isMobile) {
      setExternalUrl(response.url)
    }
  }

  useEffect(() => {
    if (!object.ssoModals) {
      return
    }

    const targets = []

    setTimeout(function () {
      const tags = document.getElementsByClassName("toggle-sso-modal")

      for (const tag of tags) {
        targets.push(tag)
        tag.addEventListener("click", openModal)
      }
    }, 100)

    return () => {
      if (targets.length > 0) {
        targets.forEach((tag) => {
          tag.removeEventListener("click", openModal)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object])

  if (!object.ssoModals) {
    return null
  }

  const customStyles = {
    overlay: {
      display: "flex",
      backgroundColor: "rgba(0, 0, 0, .5)",
      zIndex: 1000,
      alignItems: "center",
      justifyContent: "center"
    }
  }

  return (
    <>
      <ReCAPTCHA
        className="hidden"
        ref={recaptchaRef}
        size="invisible"
        sitekey={siteKey}
      />
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        shouldReturnFocusAfterClose={true}
        shouldCloseOnOverlayClick={true}
        className={classNames(styles.content)}>
        <div className="content-wrapper">
          {!externalUrl && <h3>{message}</h3>}
          {loading && <LogoSpinner className="spinner" />}
          {externalUrl && (
            <Button
              tagName="a"
              ariaLabel="Klicka för att komma vidare"
              theme="lime"
              target="_blank"
              href={externalUrl}>
              Klicka för att komma vidare
            </Button>
          )}
          {showError && (
            <Button ariaLabel={button} theme="lime" onClick={() => reset()}>
              {button}
            </Button>
          )}
        </div>
      </Modal>
    </>
  )
}

export const deleteSSOCookies = () => {
  const cookies = ["nature.media", "nature.forum"]
  cookies.forEach((cookie) => {
    if (hasCookie(cookie)) {
      deleteCookie(cookie)
    }
  })
}
