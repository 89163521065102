import { useState } from "react"
import Link from "./link"
import Menu from "./menu"
import { Caret } from "@/components/common/icons/caret"
import classNames from "classnames"

export default function Item({
  item,
  className,
  location,
  caret = false,
  hover = false
}) {
  const hasSubMenu = item.items.length > 0
  const [open, setOpen] = useState(false)

  return (
    <li
      className={className}
      role="menuitem"
      onMouseEnter={() => hover && hasSubMenu && setOpen(true)}
      onMouseLeave={() => hover && hasSubMenu && setOpen(false)}>
      <Link item={item} location={location}>
        {hasSubMenu && (
          <div
            className="toggle"
            onClick={(e) => {
              e.preventDefault()
              !hover && setOpen(!open)
            }}>
            <Caret className={classNames({ caret: true, open: open })} />
          </div>
        )}
        {caret && (
          <Caret className={classNames({ caret: true, right: true })} />
        )}
      </Link>
      {open && (
        <Menu
          menuClasses="sub-menu"
          items={item.items}
          location={"sub-" + location}
        />
      )}
    </li>
  )
}
